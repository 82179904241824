// src/components/RentalPolicies.js
import React from "react";

const RentalPolicies = () => {
  return (
    <div className="p-8 max-w-3xl mx-auto">
      <h1 className="mb-12 text-3xl md:text-4xl font-semibold leading-10 text-center tracking-tight flex justify-center">
        Rental Policies and Requirements
      </h1>
      <ol className="list-decimal list-inside space-y-10 text-base font-medium leading-7 text-[#1B2430] text-justify">
        <li>
          Please check the vehicles section to see the mileage allowance for
          your rental. If you decide to book a certain type of car, you will get
          the exact same one as shown on our website.
        </li>
        <li>
          Once a reservation with Auto rentals Az is made, 100% payment is due
          at reservation to hold the date(s)/car(s).
          <br />
          There is no penalty for cancellation or rescheduling up to 72 hours
          before the rental date. There will be a penalty of 50% of the total
          rental cost if the rental is canceled less than 48 hours prior to the
          rental.
        </li>
        <li>
          If you cancel within 24 hours of your rental (or if you do not show up
          at the time of your rental), you will be charged a cancellation fee of
          100% of the total rental cost.
        </li>
        <li>
          Rental extensions depend on availability and approval by Auto Rentals
          AZ.
        </li>
        <li>
          All drivers must be 21 years and older with a valid driver’s license
          and clean driving records (no major moving violations, DUI
          convictions, or similar infractions).
        </li>
        <li>
          Customers are required to have their own full-coverage insurance
          (liability, comprehensive, collision and loss of use) and to send a
          declaration page of their insurance coverage to us by email. One of
          Auto Rentals AZ employees will verify with your insurance company if
          that coverage will transfer over to the vehicle being rented. Each
          driver is required to provide their own car insurance that covers upto:
          <ul className="list-disc list-inside ml-6">
            <li>$25,000 Bodily Injury Liability per person</li>
            <li>$50,000 Bodily Injury per accident</li>
            <li>$50,000 Property Damage Liability</li>
          </ul>{" "}
          and has full comprehensive & collision coverage.
        </li>
        <li>
          Additional drivers may be listed as authorized drivers. Additional
          drivers may be listed on your Rental Agreement at no additional
          charge. Each additional driver must submit a driver’s license in
          advance and must have the above qualifications (age, licensing and
          driving-record restrictions, valid insurance coverage, etc).
        </li>
      </ol>
    </div>
  );
};

export default RentalPolicies;
