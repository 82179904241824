import {
  CanopyPayload,
  CanopyResponse
} from "api/types";
import { AxiosError } from "axios";
import customAxios from "config";
import toast from "react-hot-toast";

export const CreateCanopyVerification = async (
  payload: CanopyPayload
): Promise<{ data?: CanopyResponse; error?: string }> => {
  try {
    const response = await customAxios.post<CanopyResponse>(
      "/api/canopy/create-insurance-verification-link/",
      payload
    );

    return response;
  } catch (error: any) {
    if (error instanceof AxiosError) {
      toast.error(error.response?.data.error);
    }
    return { error: "An unknown error occurred." };
  }
};
