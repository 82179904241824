import React from "react";
import CarRentalImg from "images/carrental.png";
import Coupon from "images/coupon.svg";
import Phone from "images/phone.svg";
import Laptop from "images/laptop.svg";
import NcImage from "shared/NcImage/NcImage";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useNavigate } from "react-router-dom";

const LocationDiscount: React.FC = () => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col items-center bg-[#FFF6ED] py-8">
      <div className="flex flex-col md:flex-row md:items-center">
        <div className="md:w-1/2 flex justify-center mb-6 md:mb-0">
          <NcImage src={CarRentalImg} />
        </div>
        <div className="md:w-1/2 flex flex-col items-start md:ml-8 px-12 md:px-0">
          <h1 className="lg:text-4xl text-xl font-semibold leading-10 text-left mb-8">
            Renting A Car Has Never Been Easier
          </h1>
          <ul className="space-y-4 mb-4">
            <li className="flex items-center">
              <div className="flex-shrink-0">
                <NcImage src={Coupon} />
              </div>
              <span className="text-lg ml-4 font-bold">
                15% Off
                <br />
                <span className="font-normal text-[#6B7280]">
                  First time reservation
                </span>
              </span>
            </li>
            <li className="flex items-center">
              <div className="flex-shrink-0">
                <NcImage src={Phone} />
              </div>
              <span className="text-lg ml-4 font-bold">
                Reserve By Phone
                <br />
                <span className="font-normal text-[#6B7280]">
                  Call us 24/7 at
                  <br />
                  <a
                    href="tel:+1(248)219-4849"
                    className="text-blue-600 ml-1"
                  >
                    +1 (248) 219-4849
                  </a>
                </span>
              </span>
            </li>
            <li className="flex items-center">
              <div className="flex-shrink-0">
                <NcImage src={Laptop} />
              </div>
              <span className="text-lg ml-4 font-bold">
                Reserve Online
                <br />
                <span className="font-normal text-[#6B7280]">
                  In just a few clicks
                </span>
                <br />
                <button
                  className=" text-white font-bold py-2 px-4 rounded-xl disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 h-auto inline-flex items-center justify-center transition-colors w-40"
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  Get Started
                </button>
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default LocationDiscount;
