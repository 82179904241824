import React, { useEffect, useState } from "react";
import CardCategoryBox1 from "components/CardCategoryBox1/CardCategoryBox1";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { TaxonomyType } from "data/types";
import Heading from "components/Heading/Heading";

export interface SectionGridCategoryBoxProps {
  name?: TaxonomyType[];
  headingCenter?: boolean;
  categoryCardType?: "card1";
  className?: string;
  gridClassName?: string;
}

const SectionGridCategoryBox: React.FC<SectionGridCategoryBoxProps> = ({
  categoryCardType = "card1",
  className = "",
  gridClassName = "grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4",
}) => {
  const [showAllLocation, setShowAllLocation] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const location = [
    {
      id: "scottsdale-car-rental",
      name: "Scottsdale Car Rental",
      href: "/scottsdale-car-rental",
    },
    {
      id: "phoenix-car-rental",
      name: "Phoenix Car Rental",
      href: "/phoenix-car-rental",
    },
    {
      id: "anthem-car-rental",
      name: "Anthem Car Rental",
      href: "/anthem-car-rental",
    },
    {
      id: "tempe-car-rental",
      name: "Tempe Car Rental",
      href: "/tempe-car-rental",
    },
    {
      id: "glendale-car-rental",
      name: "Glendale Car Rental",
      href: "/glendale-car-rental",
    },
    {
      id: "peoria-car-rental",
      name: "Peoria Car Rental",
      href: "/peoria-car-rental",
    },
    {
      id: "chandler-car-rental",
      name: "Chandler Car Rental",
      href: "/chandler-car-rental",
    },
    {
      id: "mesa-car-rental",
      name: "Mesa Car Rental",
      href: "/mesa-car-rental",
    },
    {
      id: "gilbert-car-rental",
      name: "Gilbert Car Rental",
      href: "/gilbert-car-rental",
    },
    {
      id: "surprise-car-rental",
      name: "Surprise Car Rental",
      href: "/surprise-car-rental",
    },
    {
      id: "goodyear-car-rental",
      name: "Goodyear Car Rental",
      href: "/goodyear-car-rental",
    },
  ];

  let CardComponentName = CardCategoryBox1;
  switch (categoryCardType) {
    case "card1":
      CardComponentName = CardCategoryBox1;
      break;
    default:
      CardComponentName = CardCategoryBox1;
  }

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 640);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const showViewAllButton = location?.length > 8; // Ensure the length is greater than 8
  const toggleShowAll = () => {
    setShowAllLocation(!showAllLocation);
  };

  return (
    <div className={`nc-SectionGridCategoryBox relative ${className}`}>
      <div className="flex mb-8 relative justify-between">
        <Heading>Our Locations</Heading>
        {showViewAllButton && (
          <span className="hidden sm:block flex-shrink-0">
            <ButtonSecondary
              className="!leading-5 text-lg font-medium text-[#374151] rounded-full"
              onClick={toggleShowAll}
            >
              <span className="mr-3">View All</span>
              {showAllLocation ? (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18 15L12 9L6 15"
                    stroke="#374151"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              ) : (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6 9L12 15L18 9"
                    stroke="#374151"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              )}
            </ButtonSecondary>
          </span>
        )}
      </div>
      <div className={`grid ${gridClassName} gap-5 sm:gap-6 md:gap-8`}>
        {location
          ?.slice(0, showAllLocation ? location.length : 8)
          .map((item) => (
            <CardComponentName key={item.id} locationData={item} />
          ))}
      </div>
    </div>
  );
};

export default SectionGridCategoryBox;
