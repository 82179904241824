import {
  deleteTripPhoto,
  getTripPhotos,
  uploadTripPhotos,
} from "api/booking/booking";
import { useCallback, useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import toast from "react-hot-toast";

interface FileWithPreview extends File {
  preview: string;
}

interface UploadedImage {
  id: number;
  url: string;
}

export default function UploadZone({
  bookingId,
  preTrip,
}: {
  bookingId: any;
  preTrip: boolean;
}) {
  const [files, setFiles] = useState<FileWithPreview[]>([]);
  const [uploadedImages, setUploadedImages] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);

  // Fetch existing trip photos when the component loads
  const fetchPhotos = async () => {
    try {
      const photos = await getTripPhotos(bookingId);
      setUploadedImages(photos); // Ensure photos is an array
    } catch (error) {
      toast.error("Failed to fetch trip photos");
    }
  };

  useEffect(() => {
    fetchPhotos();
  }, [bookingId]);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    setFiles((prevFiles) => [
      ...prevFiles,
      ...acceptedFiles.map((file) =>
        Object.assign(file, { preview: URL.createObjectURL(file) })
      ),
    ]);
  }, []);

  const handleRemoveFile = (index: number) => {
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
  };

  const handleRemoveUploadedImage = async (id: number) => {
    try {
      await deleteTripPhoto(id);
      setUploadedImages((prevImages: any) =>
        prevImages.filter((img: any) => img.id !== id)
      );
      toast.success("Photo deleted successfully");
    } catch (error) {
      toast.error("Failed to delete photo");
    }
  };

  const startUpload = async () => {
    setIsLoading(true);
    try {
      const response = await uploadTripPhotos(files, bookingId, preTrip);

      if (response.error) {
        throw new Error(response.error);
      }

      const newPhotos = response.photos || []; // Assuming photos is the array of new photos
      if (!Array.isArray(newPhotos)) {
        throw new Error("Invalid response format: expected an array of photos");
      }

      // Append new photos to the state
      setUploadedImages((prevImages: any) => [...prevImages, ...newPhotos]);
      setFiles([]); // Clear files after successful upload
      toast.success("Files uploaded successfully");
      fetchPhotos();
    } catch (error) {
      toast.error("Upload failed");
    } finally {
      setIsLoading(false);
    }
  };

  const clearFiles = () => {
    setFiles([]);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpg", ".jpeg"],
    },
  });

  function formatDate(dateString: string) {
    const date = new Date(dateString);

    // Correct options for formatting the date
    const options: Intl.DateTimeFormatOptions = {
      day: "numeric",
      month: "long",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };

    // Get the formatted date
    const formattedDate = date.toLocaleString("en-GB", options);

    return formattedDate;
  }

  return (
    <div className="  rounded-md">
      <div className="border  rounded-lg p-4">
        <div
          {...getRootProps()}
          className="cursor-pointer p-4 border-2 border-dotted border-gray-300 rounded-md"
        >
          <input {...getInputProps()} />
          <div className="flex flex-col items-center">
            {/* Upload Icon */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-12 h-12 text-gray-400"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3 16.5v3a1.5 1.5 0 001.5 1.5h15a1.5 1.5 0 001.5-1.5v-3M7.5 12l4.5-4.5m0 0L16.5 12m-4.5-4.5V21"
              />
            </svg>

            <p className="text-gray-500">Drop or select files</p>
          </div>
        </div>
        {!isLoading && files?.length > 0 && (
          <div>
            {/* Display images in a grid layout */}
            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4">
              {files?.map((file, index) => (
                <div key={index} className="relative mt-4">
                  {file.type.startsWith("image") && (
                    <div className="group relative h-[115px] w-full rounded-md bg-gray-50">
                      <img
                        src={file.preview}
                        alt={file.name}
                        className="rounded-md object-cover h-full w-full"
                      />
                    </div>
                  )}
                </div>
              ))}
            </div>

            {/* Clear and Upload buttons */}
            <div className="flex gap-4 mt-4">
              <button
                onClick={clearFiles}
                className="bg-gray-200 text-gray-700 py-2 px-4 rounded-lg flex items-center"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-5 h-5 mr-2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19.5 9.75L19.5 18.75C19.5 19.9926 18.4926 21 17.25 21L6.75 21C5.50736 21 4.5 19.9926 4.5 18.75L4.5 9.75M9.75 9.75V16.5M14.25 9.75V16.5M3 6.75H21M9.75 6.75V4.5C9.75 3.94772 10.1977 3.5 10.75 3.5H13.25C13.8023 3.5 14.25 3.94772 14.25 4.5V6.75"
                  />
                </svg>
                Clear {files.length} files
              </button>

              <button
                onClick={startUpload}
                className="bg-blue-500 text-white py-2 px-4 rounded-lg flex items-center"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-5 h-5 mr-2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3 16.5v3a1.5 1.5 0 001.5 1.5h15a1.5 1.5 0 001.5-1.5v-3M7.5 12l4.5-4.5m0 0L16.5 12m-4.5-4.5V21"
                  />
                </svg>
                Upload {files.length} files
              </button>
            </div>
          </div>
        )}

        {!files?.length && !uploadedImages?.length && (
          <p className="text-gray-400 mt-2">No files selected or uploaded</p>
        )}
      </div>
      {/* Uploaded Images */}
      {uploadedImages?.filter((image: any) =>
        preTrip
          ? image.image_type === "pre_trip"
          : image.image_type === "post_trip"
      ).length > 0 && (
        <>
          <h2 className="font-bold text-2xl my-4">Uploaded Files</h2>
          <div className="mt-4 grid grid-cols-2 sm:grid-cols-4 gap-4">
            {uploadedImages
              ?.filter((image: any) =>
                preTrip
                  ? image.image_type === "pre_trip"
                  : image.image_type === "post_trip"
              )
              ?.map((image: any) => (
                <div key={image.id} className="relative">
                  <img
                    src={image?.image}
                    alt={`Uploaded Image ${image.id}`}
                    className="rounded-md object-cover h-[150px] w-full"
                  />
                  <p className="mt-2 font-bold">
                    {image?.added_by?.first_name} {image?.added_by?.last_name}
                  </p>
                  <p className="text-gray-600 font-semibold">
                    {formatDate(image?.created_at)}
                  </p>
                </div>
              ))}
          </div>
          <div className="border-t border-gray-300 pr-6 mt-12"></div>
        </>
      )}

      {/* File Preview */}
      <div className="mt-4 grid grid-cols-2 sm:grid-cols-4 gap-4"></div>

      {/* Upload and Clear Buttons */}

      {/* Loading Spinner */}
      {isLoading && (
        <div className="flex justify-center mt-4">
          <LoadingSpinner />
        </div>
      )}
    </div>
  );
}

// Loading spinner component
function LoadingSpinner() {
  return (
    <svg
      className="animate-spin h-5 w-5 text-blue-500"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        className="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        strokeWidth="4"
      ></circle>
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8"
      ></path>
    </svg>
  );
}
