import React from "react";

import LocationHeader from "components/LocationHeader/LocationHeader";
import LocationCard from "components/LocationCard/LocationCard";
import ScottsdaleContent from "components/LocationContent/ScottsdaleContent";
import SectionGridFeaturePlaces from "containers/PageHome/SectionGridFeaturePlaces";
import LocationGoPlacesWithUs from "components/LocationGoPlacesWithUs/LocationGoPlacesWithUs";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import LocationDiscount from "components/LocationDiscount/LocationDiscount";
import GilbertContent from "components/LocationContent/GilbertContent";
import { Helmet } from "react-helmet";
function Gilbert() {
  return (
    <>
      <Helmet>
        <title>Gilbert, AZ Car Rental | AutoRentalsAZ</title>
        <meta
          name="description"
          content="Rent a car in Gilbert, AZ with AutoRentalsAZ. Affordable rates, excellent service, and a wide range of vehicles to choose from. Explore Gilbert with ease!"
        />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <div>
        <LocationHeader name={"Gilbert, AZ"} />
        <div className="py-16">
          <LocationCard />
        </div>
        <div className="py-16">
          <GilbertContent />
        </div>
        <div className="container relative py-16">
          <SectionGridFeaturePlaces />
        </div>
        <div className="py-16">
          <LocationGoPlacesWithUs />
        </div>
        <div className="relative py-16 lg:py-28">
          <BackgroundSection />
          <SectionClientSay uniqueClassName="PageAbout_" />
        </div>
        <div className="py-16">
          <LocationDiscount />
        </div>
      </div>
    </>
  );
}

export default Gilbert;
