import { StripePayload, StripeResponse } from "api/types";
import { AxiosError } from "axios";
import customAxios from "config";

export const CreateStripeVerification = async (
  payload: StripePayload
): Promise<{ data?: StripeResponse; error?: string }> => {
  try {
    const response = await customAxios.post<StripeResponse>(
      "/api/identity/create_verification_session/",
      payload
    );
    return response;
  } catch (error) {
    if (error instanceof AxiosError) {
      return {
        error:
          error.response?.data?.detail ||
          "An error occurred during stripe verification.",
      };
    }
    return { error: "An unknown error occurred." };
  }
};

export const CreateStripeIntent = async (
  payload: any
): Promise<{ data?: any; error?: string }> => {
  console.log(payload);
  try {
    const response = await customAxios.post<any>(
      "/api/payment/create_payment_intent/",
      payload
    );
    return response;
  } catch (error) {
    if (error instanceof AxiosError) {
      return {
        error:
          error.response?.data?.detail ||
          "An error occurred during creating stripe intent",
      };
    }
    return { error: "An unknown error occurred." };
  }
};

export const SavePaymentMethod = async (
  payload: any
): Promise<{ data?: any; error?: string }> => {
  try {
    const response = await customAxios.post<any>(
      "/api/payment/manage-payment-method/",
      payload
    );
    return response;
  } catch (error) {
    if (error instanceof AxiosError) {
      return {
        error:
          error.response?.data?.detail ||
          "An error occurred during saving the card",
      };
    }
    return { error: "An unknown error occurred." };
  }
};

export const PaymentDone = async (
  payment_method: any,
  bookingId: any
): Promise<{ data?: any; error?: string }> => {
  const payload = {
    payment_method_id: payment_method,
    booking_id: bookingId,
  };

  try {
    const response = await customAxios.post<any>(
      "/api/payment/charge-payment/",
      payload
    );

    return { data: response.data };
  } catch (error: any) {
    if (error instanceof AxiosError) {
      // toast.error(error.response?.data.error);
    }
    return { error: error.response?.data.error };
  }
};

export const getSavedPaymentMethod = async (): Promise<any> => {
  try {
    const response = await customAxios.get<any>(
      "/api/payment/manage-payment-method/"
    );
    return response.data;
  } catch (error) {
    if (error instanceof AxiosError) {
      return {
        error:
          error.response?.data?.detail ||
          "An error occurred during getting saved card information",
      };
    }
    return { error: "An unknown error occurred." };
  }
};

export const removeSavedPaymentMethod = async (
  payment_method_id: string
): Promise<any> => {
  try {
    const response = await customAxios.delete<any>(
      "/api/payment/manage-payment-method/",
      {
        data: {
          payment_method_id: payment_method_id, // Sending the ID in the request body
        },
      }
    );
    return response.data;
  } catch (error) {
    if (error instanceof AxiosError) {
      return {
        error:
          error.response?.data?.detail ||
          "An error occurred while removing the payment method",
      };
    }
    return { error: "An unknown error occurred." };
  }
};
