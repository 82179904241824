import React from "react";

import LocationHeader from "components/LocationHeader/LocationHeader";
import LocationCard from "components/LocationCard/LocationCard";
import ScottsdaleContent from "components/LocationContent/ScottsdaleContent";
import SectionGridFeaturePlaces from "containers/PageHome/SectionGridFeaturePlaces";
import LocationGoPlacesWithUs from "components/LocationGoPlacesWithUs/LocationGoPlacesWithUs";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import LocationDiscount from "components/LocationDiscount/LocationDiscount";
import AnthemContent from "components/LocationContent/AnthemContent";
import GlendaleContent from "components/LocationContent/GlendaleContent";
import { Helmet } from "react-helmet";
function Glendale() {
  return (
    <>
      <Helmet>
        <title>Glendale, AZ Car Rental | AutoRentalsAZ</title>
        <meta
          name="description"
          content="Rent a car in Glendale, AZ with AutoRentalsAZ. Affordable rates, excellent service, and a wide range of vehicles to choose from. Explore Glendale with ease!"
        />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <div>
        <LocationHeader name={"Glendale, AZ"} />
        <div className="py-16">
          <LocationCard />
        </div>
        <div className="py-16">
          <GlendaleContent />
        </div>
        <div className="container relative py-16">
          <SectionGridFeaturePlaces />
        </div>
        <div className="py-16">
          <LocationGoPlacesWithUs />
        </div>
        <div className="relative py-16 lg:py-28">
          <BackgroundSection />
          <SectionClientSay uniqueClassName="PageAbout_" />
        </div>
        <div className="py-16">
          <LocationDiscount />
        </div>
      </div>
    </>
  );
}

export default Glendale;
