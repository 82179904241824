import React, { FC, ReactNode } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";

export interface SectionHeroProps {
  className?: string;
  rightImg: string;
  heading: ReactNode;
  subHeading: string;
  btnText: string;
}

const SectionHero: FC<SectionHeroProps> = ({
  className = "",
  rightImg,
  heading,
  subHeading,
  btnText,
}) => {
  return (
    <div
      className={`nc-SectionHero relative ${className} mb-20 lg:mb-36`}
      data-nc-id="SectionHero"
    >
      <div className="flex flex-col lg:flex-row items-start relative text-left">
        <div className="w-full lg:w-1/2 max-w-full xl:max-w-lg space-y-5 lg:space-y-7">
          <h2 className="text-3xl !leading-tight font-semibold lg:font-bold text-neutral-900 md:text-4xl xl:text-5xl dark:text-neutral-100 mb-6 lg:mb-0">
            {heading}
          </h2>
          {!!btnText && <ButtonPrimary href="/signup">{btnText}</ButtonPrimary>}
        </div>
        <div className="w-full lg:w-1/2 flex justify-center lg:justify-end items-start lg:items-center">
          <span className="text-base xl:text-lg text-neutral-6000 dark:text-neutral-400 font-semibold">
            {subHeading}
          </span>
        </div>
      </div>
    </div>
  );
};

export default SectionHero;
