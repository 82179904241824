import { AxiosError } from "axios";
import customAxios from "config";
import { globalJson } from "global/global_json";

export const getCompanyInfoFun = async (): Promise<any> => {
  try {
    const response = await customAxios.get("/api/company/configurations/", {
      params: {
        company_id:  process.env.REACT_APP_ENV !== "prod"
        ? globalJson.dev_company_id
        : globalJson.prod_company_id,
      },
    });
    return response.data;
  } catch (error) {
    if (error instanceof AxiosError && error.response) {
      return {
        error:
          error.response.data.detail ||
          "An error occurred during fetching company data",
      };
    }
    return { error: "An unknown error occurred." };
  }
};
