import React from "react";
import imagePng from "images/location.png";

interface LocationHeaderProps {
  name: string;
}

const LocationHeader: React.FC<LocationHeaderProps> = ({ name }) => {
  return (
    <div>
      <div className={`nc-SectionHero3 relative`} data-nc-id="SectionHero3">
        <div className="absolute inset-x-0 top-[20%] md:top-[40%] lg:top-[30%] text-center flex flex-col items-center mx-auto space-y-3 lg:space-y-3 xl:space-y-5" style={{zIndex:1}}>
          <h2 className="text-3xl md:text-4xl font-bold leading-9 text-center text-white">
            Auto Rentals AZ in
          </h2>
          <span className="text-lg lg:text-7xl font-semibold leading-10 text-center text-white">
            {name}
          </span>
        </div>

        <div className="relative aspect-w-1 aspect-h-1 sm:aspect-w-4 sm:aspect-h-3 lg:aspect-w-16 lg:aspect-h-6 xl:aspect-h-6">
          <img
            className="absolute inset-0 object-cover w-full h-full"
            src={imagePng}
            alt="hero"
          />
        </div>
      </div>
    </div>
  );
};

export default LocationHeader;
