import React, { useState } from "react";
import Minus from "images/Minus.svg";
import Plus from "images/Plus.svg";
import NcImage from "shared/NcImage/NcImage";

interface SectionProps {
  title: string;
  children: React.ReactNode;
  isOpen: boolean;
  onToggle: () => void;
}

const Section: React.FC<SectionProps> = ({
  title,
  children,
  isOpen,
  onToggle,
}) => {
  return (
    <div className="border-b border-gray-200">
      <button
        onClick={onToggle}
        className="flex justify-between w-full px-4 py-6 text-left items-center"
      >
        <span className="text-lg font-medium leading-7 text-left">{title}</span>
        <span>{isOpen ? <NcImage src={Minus} /> : <NcImage src={Plus} />}</span>
      </button>
      {isOpen && <div className="px-4 py-2">{children}</div>}
    </div>
  );
};

const FAQ: React.FC = () => {
  const [openSectionIndex, setOpenSectionIndex] = useState<number | null>(null);

  const handleToggle = (index: number) => {
    setOpenSectionIndex(openSectionIndex === index ? null : index);
  };
  return (
    <>
      <div className="flex justify-center mb-16">
        <span className="text-3xl md:text-4xl font-semibold leading-10 text-center tracking-tight">
          FAQ
        </span>
      </div>
      <div className="max-w-3xl mx-auto">
        <div className="mt-4 mx-2">
          <span className="md:text-xl text-lg font-semibold leading-10 text-center">
            General Questions
          </span>
        </div>
        <Section
          title="What documents do I need to rent a car?"
          isOpen={openSectionIndex === 0}
          onToggle={() => handleToggle(0)}
        >
          <span className="text-base font-normal leading-6 text-left text-[#6B7280]">
            <span>
              You will need a valid driver's license, a credit card in your name
              and a proof of insurance which includes a declaration page.
            </span>
          </span>
        </Section>
        <Section
          title="How old do I need to be to rent a car?"
          isOpen={openSectionIndex === 1}
          onToggle={() => handleToggle(1)}
        >
          <span className="text-base font-normal leading-6 text-left text-[#6B7280]">
            <span>
              The minimum age requirement is 21 years old. Additional drivers
              may occur a fee.
            </span>
          </span>
        </Section>
        <Section
          title="Is there a security deposit required ?"
          isOpen={openSectionIndex === 2}
          onToggle={() => handleToggle(2)}
        >
          <span className="text-base font-normal leading-6 text-left text-[#6B7280]">
            <span>
              Yes. Security deposit varies from $500-2000 depending on the
              vehicle.
            </span>
          </span>
        </Section>
        <Section
          title="Can I modify or cancel my reservation?"
          isOpen={openSectionIndex === 3}
          onToggle={() => handleToggle(3)}
        >
          <span className="text-base font-normal leading-6 text-left text-[#6B7280]">
            <span>
              Yes, you can modify or cancel your reservation online or by
              calling customer service. Cancellation policies may vary and fees
              may apply.
            </span>
          </span>
        </Section>
        <Section
          title="What forms of payment are accepted?"
          isOpen={openSectionIndex === 4}
          onToggle={() => handleToggle(4)}
        >
          <span className="text-base font-normal leading-6 text-left text-[#6B7280]">
            <span>
              We accept major credit cards and, in some cases, debit cards. Cash
              and checks are not accepted.
            </span>
          </span>
        </Section>
        <Section
          title="Is there a mileage limit?"
          isOpen={openSectionIndex === 5}
          onToggle={() => handleToggle(5)}
        >
          <span className="text-base font-normal leading-6 text-left text-[#6B7280]">
            <span>
              Rentals come with 150 miles a day limit. We do offer special
              pricing for more miles. Please contact us for specific details.
            </span>
          </span>
        </Section>
        <Section
          title="What is the fuel policy?"
          isOpen={openSectionIndex === 6}
          onToggle={() => handleToggle(6)}
        >
          <span className="text-base font-normal leading-6 text-left text-[#6B7280]">
            <span>
              The fuel policy requires all rentals to return the car with a full
              tank. We also offer prepaid fuel options for convenience.
            </span>
          </span>
        </Section>
        <Section
          title="Are there any additional fees I should be aware of?"
          isOpen={openSectionIndex === 7}
          onToggle={() => handleToggle(7)}
        >
          <span className="text-base font-normal leading-6 text-left text-[#6B7280]">
            <span>
              Additional fees can include additional driver fees, airport
              surcharges, returning vehicle without filling up gas or going over
              miles allowed. Be sure to review the terms and conditions.
            </span>
          </span>
        </Section>
        <Section
          title="Do I need to purchase rental car insurance?"
          isOpen={openSectionIndex === 8}
          onToggle={() => handleToggle(8)}
        >
          <span className="text-base font-normal leading-6 text-left text-[#6B7280]">
            <span>
              Insurance is not always required, but it's recommended. Your
              personal auto insurance or credit card may provide coverage.
              Please check with your insurance provider.
            </span>
          </span>
        </Section>
        <Section
          title="What happens if the car gets damaged?"
          isOpen={openSectionIndex === 9}
          onToggle={() => handleToggle(9)}
        >
          <span className="text-base font-normal leading-6 text-left text-[#6B7280]">
            <span>
              If the car is damaged, you will be responsible for the repair
              costs.
            </span>
          </span>
        </Section>
        <Section
          title="Can I bring my pet in the vehicle?"
          isOpen={openSectionIndex === 10}
          onToggle={() => handleToggle(10)}
        >
          <span className="text-base font-normal leading-6 text-left text-[#6B7280]">
            <span>
              No. Pets and Smoking are not allowed. Additional cleaning fee of
              $250 will be applied if policy is violated.
            </span>
          </span>
        </Section>
        <Section
          title="Can I return the car to a different location?"
          isOpen={openSectionIndex === 11}
          onToggle={() => handleToggle(11)}
        >
          <span className="text-base font-normal leading-6 text-left text-[#6B7280]">
            <span>
              Yes, one-way rentals are possible but may incur an additional fee.
              Confirm in advance.
            </span>
          </span>
        </Section>
        <Section
          title="What if I return the car late?"
          isOpen={openSectionIndex === 12}
          onToggle={() => handleToggle(12)}
        >
          <span className="text-base font-normal leading-6 text-left text-[#6B7280]">
            <span>
              Late returns may result in additional charges. Check with Auto
              rentals AZ.
            </span>
          </span>
        </Section>
        <div className="mt-4 mx-2">
          <span className="md:text-xl text-lg font-semibold leading-10 ">
            Special Requests and Accommodations
          </span>
        </div>
        <Section
          title="Can I add an additional driver?"
          isOpen={openSectionIndex === 13}
          onToggle={() => handleToggle(13)}
        >
          <span className="text-base font-normal leading-6 text-left text-[#6B7280]">
            <span>
              Yes, additional drivers can be added to the rental agreement, for
              an extra fee. They must meet the same age and license
              requirements.
            </span>
          </span>
        </Section>
        <Section
          title="Are child seats or GPS units available?"
          isOpen={openSectionIndex === 15}
          onToggle={() => handleToggle(15)}
        >
          <span className="text-base font-normal leading-6 text-left text-[#6B7280]">
            <span>
              Yes, we offer child seats, GPS units, and other accessories for an
              additional fee. It's best to reserve these items in advance.
            </span>
          </span>
        </Section>
        <Section
          title="Can I rent a car for a long-term period?"
          isOpen={openSectionIndex === 16}
          onToggle={() => handleToggle(16)}
        >
          <span className="text-base font-normal leading-6 text-left text-[#6B7280]">
            <span>
              Yes, we offer long-term rentals with special rates. Contact
              customer service for more information.
            </span>
          </span>
        </Section>
        <Section
          title="How can I contact customer service?"
          isOpen={openSectionIndex === 17}
          onToggle={() => handleToggle(17)}
        >
          <span className="text-base font-normal leading-6 text-left text-[#6B7280]">
            <span>
              Customer service can be reached by phone, email, or through the
              company’s website.
            </span>
          </span>
        </Section>
      </div>
    </>
  );
};

export default FAQ;
