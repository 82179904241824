import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import GoPlacesWith from "components/GoPlacesWith/GoPlacesWith";
import Label from "components/Label/Label";
import RentalSteps from "components/RentalSteps/RentalSteps";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import { globalJson } from "global/global_json";
import React, { useState } from "react";
import toast from "react-hot-toast";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import SocialsList from "shared/SocialsList/SocialsList";
import { sendNewsLetterEmail } from "utils/apiUtil";
import RentalPolicies from "components/RentalPolicies/RentalPolicies";
import FAQ from "components/FAQ/FAQ";


const info = [
  {
    title: "🗺 ADDRESS",
    desc: globalJson.address,
  },
  {
    title: "💌 EMAIL",
    desc: globalJson.email,
  },
  {
    title: "☎ PHONE",
    desc: globalJson.phone,
  },
];

function Requirements() {
  const [getEmail, setEmail] = useState("");
  const [newName, setNewName] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const sendEmail = async (e: React.FormEvent) => {
    setLoading(true);
    e.preventDefault();
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!getEmail.trim() || !emailRegex.test(getEmail)) {
      toast.error("Please enter a valid email address");
      setLoading(false);
      return;
    }

    if (getEmail.length == 0 || newName.length == 0 || message.length == 0) {
      toast.error("Fields are required!");
      setLoading(false);
      return;
    }
    await sendNewsLetterEmail(getEmail, newName, message);
    setEmail("");
    setNewName("");
    setMessage("");
    toast.success("Send successfully!");
    setLoading(false);
  };
  return (
    <div>
      <div className="pb-16 flex items-center justify-center ">
      <RentalPolicies/>
      </div>
      <div className="py-16">
       <FAQ/>
      </div>
      <div className="py-16">
        <RentalSteps />
      </div>

      <div className="py-16">
        <GoPlacesWith />
      </div>
    
    </div>
  );
}

export default Requirements;
