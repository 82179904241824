import Heading from "components/Heading/Heading";
import React, { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import HIW1img from "images/LGPWS1.png";
import HIW2img from "images/LGPWS2.png";
import HIW3img from "images/LGPWS3.png";
import HIW4img from "images/LGPWS4.png";
import HIW5img from "images/LGPWS5.png";
import HIW6img from "images/LGPWS6.png";
import VectorImg from "images/VectorHIW.svg";
import { useLocation } from "react-router-dom";

export interface SectionHowItWorkProps {
  className?: string;
  data?: {
    id: number;

    desc: string;
    img: string;
    imgDark?: string;
  }[];
  data2?: {
    id: number;

    desc: string;
    img: string;
    imgDark?: string;
  }[];
}

const DEMO_DATA: SectionHowItWorkProps["data"] = [
  {
    id: 1,
    img: HIW1img,
    desc: "No hidden fees",
  },
  {
    id: 2,
    img: HIW2img,
    desc: "No Vehicle substitutions. Drive the car you paid for",
  },
  {
    id: 3,
    img: HIW3img,
    desc: "FREE vehicle delivery on 14 + day reservations",
  },
];
const DEMO_DATA2: SectionHowItWorkProps["data"] = [
  {
    id: 1,
    img: HIW4img,
    desc: "Exclusive rates for returning clientele",
  },
  {
    id: 2,
    img: HIW5img,
    desc: "Drive Commercially insured & protected vehicles.",
  },
  {
    id: 3,
    img: HIW6img,
    desc: "UNLIMITED Mileage on select Vehicles.",
  },
];

const LocationGoPlacesWithUs: FC<SectionHowItWorkProps> = ({
  className = "",
  data = DEMO_DATA,
}) => {
  const location = useLocation();
  const isScottsdaleCarRental = location.pathname === "/scottsdale-car-rental";
  return (
    <div
      className={`nc-SectionHowItWork  ${className}`}
      data-nc-id="SectionHowItWork"
    >
      <div className="flex flex-col mb-8 relative">
        <span className=" text-3xl md:text-4xl font-semibold leading-6 md:leading-10 text-center">
          Go Places With Us!
        </span>
      </div>
      <div className="mt-20 relative grid md:grid-cols-3 gap-20  mx-4">
        <img
          className="hidden md:block absolute inset-x-0 top-10"
          src={VectorImg}
          alt=""
        />
        {data.map((item) => (
          <div
            key={item.id}
            className="relative flex flex-col items-center max-w-xs mx-auto"
          >
            <NcImage
              containerClassName="mb-8 mx-auto"
              src={item.img}
            />

            <div className="text-center mt-auto">
              <span className="text-xl font-semibold leading-5 text-center">
                {item.desc}
              </span>
            </div>
          </div>
        ))}
      </div>
      <div className="mt-20 relative grid md:grid-cols-3 gap-20  mx-4">
        <img
          className="hidden md:block absolute inset-x-0 top-10"
          src={VectorImg}
          alt=""
        />
        {DEMO_DATA2.map((item, index) => {
          // Determine if the current item should be displayed
          const isLastItem = index === DEMO_DATA2.length - 1;

          // Display the item if it's not the last item or if it's the last item and the path matches
          if (!isLastItem || (isLastItem && isScottsdaleCarRental)) {
            return (
              <div
                key={item.id}
                className="relative flex flex-col items-center max-w-xs mx-auto"
              >
                <NcImage
                  containerClassName="mb-8 mx-auto"
                  src={item.img}
                />
                <div className="text-center mt-auto">
                  <span className="text-xl font-semibold  leading-5 text-justify">
                    {item.desc}
                  </span>
                </div>
              </div>
            );
          }
          return null; // Do not render if conditions are not met
        })}
      </div>
    </div>
  );
};

export default LocationGoPlacesWithUs;
