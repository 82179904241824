import React from "react";

import LocationHeader from "components/LocationHeader/LocationHeader";
import LocationCard from "components/LocationCard/LocationCard";
import ScottsdaleContent from "components/LocationContent/ScottsdaleContent";
import SectionGridFeaturePlaces from "containers/PageHome/SectionGridFeaturePlaces";
import LocationGoPlacesWithUs from "components/LocationGoPlacesWithUs/LocationGoPlacesWithUs";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import LocationDiscount from "components/LocationDiscount/LocationDiscount";
import ChandlerContent from "components/LocationContent/ChandlerContent";
import { Helmet } from "react-helmet";
function Chandler() {
  return (
    <>
    <Helmet>
        <title>Chandler, AZ Car Rental | AutoRentalsAZ
        </title>
        <meta
          name="description"
          content="Rent a car in Chandler, AZ with AutoRentalsAZ. Affordable rates, excellent service, and a wide range of vehicles to choose from. Explore Chandler with ease!"
        />
        <meta name="robots" content="index, follow" />
      </Helmet>
    <div>
      <LocationHeader name={"Chandler, AZ"} />
      <div className="py-16">
        <LocationCard />
      </div>
      <div className="py-16">
        <ChandlerContent/>
      </div>
      <div className="container relative py-16">
        <SectionGridFeaturePlaces />
      </div>
      <div className="py-16">
        <LocationGoPlacesWithUs />
      </div>
      <div className="relative py-16 lg:py-28">
        <BackgroundSection />
        <SectionClientSay uniqueClassName="PageAbout_" />
      </div>
      <div className="py-16">
        <LocationDiscount />
      </div>
    </div>
    </>
  );
}

export default Chandler;
