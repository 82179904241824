import React, { FC } from "react";
import rentalStep1 from "images/RentalStep1.png";
import rentalStep2 from "images/RentalStep2.png";
import rentalStep3 from "images/RentalStep3.png";
import NcImage from "shared/NcImage/NcImage";
import VectorImg from "images/VectorHIW.svg";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Link, useNavigate } from "react-router-dom";

export interface RentalStepProp {
  className?: string;
  data?: {
    id: number;
    title: string;
    desc: string;
    img: string;
    imgDark?: string;
  }[];
}

const DEMO_DATA: RentalStepProp["data"] = [
  {
    id: 1,
    img: rentalStep1,
    title: "International Renters",
    desc: "Vehicles are available for rent to international visitors with a valid passport and driver’s license from their home country. However, any international renter would need to get a US auto coverage from any third-party company (for example, AllState, State Farm). We do not accept credit card additional car rental coverage.",
  },
  {
    id: 2,
    img: rentalStep2,
    title: "Security Deposit",
    desc: "A security deposit (in the form of a temporary authorization/hold on your credit card) is due at the time of booking and will be released if vehicle is returned in the same condition one days later. (i.e. If car is returned with no damage on a Monday, we will process the return of your deposit on Tuesday – this gives us time for a thorough inspection of the vehicle). Security deposit varies from $500 to $2,500 depending on the type of car you would like to rent. If the car is returned damaged, we will hold your security deposit until we have opened a claim and cleared it with your insurance company.",
  },
  {
    id: 3,
    img: rentalStep3,
    title: "Rental Periods",
    desc: `Car rentals have a 24 hours minimum We also do long-term rentals (14+ days and monthly with exclusive rates), please contact us for more information.`,
  },
];

const RentalSteps: FC<RentalStepProp> = ({
  className = "",
  data = DEMO_DATA,
}) => {
  const navigate = useNavigate();
  return (
    <div className="container mx-auto p-6">
      <div className="md:mt-20 mt-8 relative grid lg:grid-cols-3 gap-20">
        <img
          className="hidden lg:block absolute inset-x-0 top-10"
          src={VectorImg}
          alt=""
        />
        {data.map((item) => (
          <div
          key={item.id}
          className="relative flex flex-col items-center max-w-xs mx-auto"
        >
          <NcImage
            containerClassName="mx-auto"
            src={item.img}
          />
            <div className="text-center">
              <h3 className="text-xl font-bold">{item.title}</h3>
              <span className="block mt-5 text-[#6B7280] font-normal">
                {item.desc}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RentalSteps;
