import { Tab } from "@headlessui/react";
import StayCard from "components/StayCard/StayCard";
import { Fragment, useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import {
  getBooking,
  cancelBooking as cancelBookingAPI,
} from "api/booking/booking"; // Renamed import here
import { useData } from "data/data-provider";
import CommonLayout from "./CommonLayout";
import toast from "react-hot-toast";

const AccountSavelists = () => {
  const navigate = useNavigate();

  const { user, setAllBookings, allBookings } = useData();
  let [categories] = useState(["Upcoming", "Previous", "Cancelled"]);
  let [upcoming, setUpcoming] = useState<any>([]);
  let [previous, setPrevious] = useState<any>([]);
  let [cancelled, setCancelled] = useState<any>([]);
  let [loading, setLoading] = useState(false);

  // Renamed local cancel function to avoid conflict
  const handleCancelBooking = async (bookingId: string) => {
    setLoading(true);
    try {
      await cancelBookingAPI(bookingId); // Using renamed API function here
      toast.success("Booking cancelled successfully.");
      await fetchBookings(); // Refresh bookings after cancel
    } catch (err) {
      toast.error("Error cancelling booking.");
    }
    setLoading(false);
  };

  useEffect(() => {
    if (user) fetchBookings();
  }, [user]);

  const fetchBookings = async () => {
    setLoading(true);
    if (user === null) return;
    setLoading(true);
    const data = await getBooking();

    setAllBookings(data);

    const bookings = data && Array.isArray(data) ? data : [];

    // Filter bookings into respective categories
    const upcomingBookings = bookings.filter(
      (booking: any) =>
        new Date(booking.drop_off_time) > new Date() &&
        booking.trip_status !== "cancelled" &&
        booking.payment_status === "paid"
    );

    const previousBookings = bookings.filter(
      (booking: any) =>
        new Date(booking.drop_off_time) <= new Date() &&
        booking.trip_status !== "cancelled" &&
        booking.payment_status === "paid"
    );

    const cancelledBookings = bookings.filter(
      (booking: any) =>
        booking.trip_status === "cancelled" && booking.payment_status === "paid"
    );

    // Sort bookings by pick_up_time in descending order
    setUpcoming(
      upcomingBookings.sort(
        (a: any, b: any) =>
          new Date(b.pick_up_time).getTime() -
          new Date(a.pick_up_time).getTime()
      )
    );

    setPrevious(
      previousBookings.sort(
        (a: any, b: any) =>
          new Date(b.pick_up_time).getTime() -
          new Date(a.pick_up_time).getTime()
      )
    );

    setCancelled(
      cancelledBookings.sort(
        (a: any, b: any) =>
          new Date(b.pick_up_time).getTime() -
          new Date(a.pick_up_time).getTime()
      )
    );

    setLoading(false);
  };

  const renderSection1 = () => {
    return (
      <div className="space-y-6 sm:space-y-8">
        <div>
          <h2 className="text-3xl font-semibold">Account Bookings</h2>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        <div>
          <Tab.Group>
            <Tab.List className="flex space-x-1 overflow-x-auto">
              {categories.map((item) => (
                <Tab key={item} as={Fragment}>
                  {({ selected }) => (
                    <button
                      className={`flex-shrink-0 block !leading-none font-medium px-5 py-2.5 text-sm sm:text-base sm:px-6 sm:py-3 capitalize rounded-full focus:outline-none ${
                        selected
                          ? "bg-primary-200 text-white"
                          : "text-neutral-500 dark:text-neutral-400 dark:hover:text-neutral-100 hover:text-neutral-900 hover:bg-neutral-100 dark:hover:bg-neutral-800"
                      } `}
                    >
                      {item}
                    </button>
                  )}
                </Tab>
              ))}
            </Tab.List>

            {/* Tab Panels for upcoming, previous, and cancelled bookings */}
            <Tab.Panels>
              {/* Upcoming Bookings */}
              <Tab.Panel className="mt-8">
                {loading ? (
                  <div className="flex justify-center items-center col-span-full">
                    <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full border-t-transparent border-gray-300"></div>
                  </div>
                ) : (
                  <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                    {upcoming.map((stay: any) => (
                      <StayCard
                        key={stay.id}
                        data={stay}
                        isUpcoming={true}
                        loading={loading}
                        cancelFunction={handleCancelBooking} // Pass the renamed function here
                      />
                    ))}
                  </div>
                )}
              </Tab.Panel>

              {/* Previous Bookings */}
              <Tab.Panel className="mt-8">
                {loading ? (
                  <div className="flex justify-center items-center col-span-full">
                    <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full border-t-transparent border-gray-300"></div>
                  </div>
                ) : (
                  <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                    {previous.map((stay: any) => (
                      <StayCard key={stay.id} data={stay} />
                    ))}
                  </div>
                )}
              </Tab.Panel>

              {/* Cancelled Bookings */}
              <Tab.Panel className="mt-8">
                {loading ? (
                  <div className="flex justify-center items-center col-span-full">
                    <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full border-t-transparent border-gray-300"></div>
                  </div>
                ) : (
                  <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                    {cancelled.map((stay: any) => (
                      <StayCard key={stay.id} data={stay} />
                    ))}
                  </div>
                )}
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>
      </div>
    );
  };

  return (
    <div>
      <CommonLayout>{renderSection1()}</CommonLayout>
    </div>
  );
};

export default AccountSavelists;
