import { getProfileResponse, PassChangePayload, PassChangeResponse, profileChangeeResponse, profileChangePayload } from "api/types";
import { AxiosError } from "axios";
import customAxios from "config";

export const PassChangeFun = async (
    payload: PassChangePayload
  ): Promise<{ data?: PassChangeResponse; error?: string }> => {
    try {
      const response = await customAxios.post(
        "/api/change-password/",
        payload
      );
      return { data: response.data }; // Return response data if successful
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        return {
          error: error.response.data.detail || "An error occurred during signup.",
        };
      }
      return { error: "An unknown error occurred." };
    }
  };

export const getProfileFun = async (
  ): Promise<any> => {
    try {
      const response = await customAxios.get(
        "/api/my-profile/"
      );
      return { data: response.data }; // Return response data if successful
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        return {
          error: error.response.data.detail || "An error occurred during fetching profile",
        };
      }
      return { error: "An unknown error occurred." };
    }
  };
  
  export const ProfileChangeFun = async (
    payload: FormData
  ): Promise<{ data?: profileChangeeResponse; error?: string }> => {
    try {
      const response = await customAxios.patch("/api/my-profile/", payload, {
        headers: {
          "Content-Type": "multipart/form-data", // Ensure the request is sent as multipart
        },
      });
      return { data: response.data }; // Return response data if successful
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        return {
          error: error.response.data.detail || "An error occurred during profile update.",
        };
      }
      return { error: "An unknown error occurred." };
    }
  };
  
  export const ContactFun = async (
    payload: { name: string; email: string; message: string }
  ): Promise<{ data?: any; error?: string }> => {
    try {
      // Create a FormData object to hold the name, email, and message
      const formData = new FormData();
      formData.append("name", payload.name);
      formData.append("email", payload.email);
      formData.append("message", payload.message);
  
      const response = await customAxios.post("/api/company/contact-us/", formData);
      return { data: response.data }; // Return response data if successful
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        return {
          error:
            error.response.data.detail ||
            "An error occurred during profile update.",
        };
      }
      return { error: "An unknown error occurred." };
    }
  };